import Alpine from "alpinejs";

const savedTheme: boolean = localStorage.getItem("darkMode") === "true";
const preferredTheme = window.matchMedia(
  "(prefers-color-scheme: dark)"
);

let theme: { darkMode: boolean } = Alpine.reactive({ darkMode: savedTheme || preferredTheme.matches });

const toggleDarkMode = () => {
  theme.darkMode = !theme.darkMode;
  document.querySelector("body").classList.toggle("dark");
  localStorage.setItem("darkMode", theme.darkMode.toString());
};

if (theme.darkMode) {
  document.querySelector("body").classList.add("dark");
}

export { theme, toggleDarkMode };
