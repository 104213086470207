import { theme } from "@/js/util/useDarkmode";
import { AnimationColors } from "@/js/types/AnimationColors";

const colors = {
  "white-75": "rgb(255 251 255 / 75%)",
  white: "#fff",
  bitify: {
    grayblue: {
      100: "#EBEFFF",
      200: "#D7E4FF",
      300: "#BBCAFF",
      400: "#6871B0",
      900: "#0A0B20"
    },
    blue: "#0035F5",
    lightblack: "#131426",
    black: "#010015"
  }
};

const getColorPalette = (): AnimationColors => {
  if (theme.darkMode) {
    return {
      header: {
        iconColor: colors.bitify.blue
      },
      pages: {
        home: {
          intro: {
            textColor: colors.white,
            buttonColor: colors.bitify.blue,
            buttonBg: colors.white
          },
          services: {
            separatorFromColor: colors.bitify.grayblue[200],
            separatorToColor: colors.bitify.grayblue[200],
            serviceBg: colors.bitify.blue,
            serviceTxt: colors.white,
            serviceArrowColor: colors.bitify.blue,
            serviceArrowBg: colors.white,
            serviceHeroTextColor: colors.bitify.grayblue[300]
          }
        }
      },
      footer: {
        separatorColor: colors.white,
        logoColor: colors.white,
        textColor: colors.white,
        ctaColor: colors.white,
        ctaBg: colors.bitify.blue
      }
    };
  }

  return {
    header: {
      iconColor: colors.bitify.blue
    },
    pages: {
      home: {
        intro: {
          textColor: colors.bitify.blue,
          buttonColor: colors.white,
          buttonBg: colors.bitify.blue
        },
        services: {
          separatorFromColor: colors.white,
          separatorToColor: colors.bitify.blue,
          serviceBg: colors.bitify.blue,
          serviceTxt: colors.white,
          serviceArrowColor: colors.bitify.blue,
          serviceArrowBg: colors.white,
          serviceHeroTextColor: colors.bitify.blue
        }
      }
    },
    footer: {
      separatorColor: colors.white,
      logoColor: colors.white,
      textColor: colors.white,
      ctaColor: colors.bitify.blue,
      ctaBg: colors.white
    }
  };
};

export { colors, getColorPalette };
