enum MobileBreakpoint {
  default = 768,
  homeService = 1024,

  lg = 1024,
}

function isMobile(breakpoint?: MobileBreakpoint): boolean {
  breakpoint = breakpoint || MobileBreakpoint.default;
  return window.innerWidth <= breakpoint;
}

export { MobileBreakpoint, isMobile };
